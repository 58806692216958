<template>

  <div v-if="form" class="form-view-container" :style="cssVars">
    <div class="preview-container">
        <Preview2 v-if="form && formIsOpen" :form="form" :container="true" ref="preview"/> 
        <ClosedForm v-else :form="form" />        
    </div>  
  </div>
</template>

<script>
import ClosedForm from './ClosedForm.vue'
import Preview2 from '@/components/Preview2.vue';
import ProgressBar from '@/components/ProgressBar.vue';
import { FormMixin } from '@/mixins/FormMixin';
// import { uuid } from 'vue-uuid'; 
// import Vue from 'vue'
// import VueAnalytics from 'vue-analytics'



const axios = require('axios').default;


export default {
  name: 'FormView',
  mixins: [FormMixin],
  components: {
    Preview2,
    ProgressBar, ClosedForm
  },

  data: function () {
    return {
      slug: this.$route.params.slug,
      form: null,
      public_uuid: localStorage.uuid
    }
  },

  mounted: function() {      
      // if(this.slug) this.getForm(this.slug, true);
      this.setUuid();
  },

  computed: {
    formIsOpen() {
      if(this.form.status == "closed" || this.form.limited == true) return false
      return true
    }
  },


  methods: {      
      setUuid(){
        localStorage.uuid = this.$uuid.v4();
      },



  }

}

</script>

<style lang="sass">

#beacon-container
  // display: none!important

.form-view-container
    width: 100%
    height: 100%
    display: flex
    align-items: center
    justify-content: center
    background-size: cover
    // background-image: var(--bg)
    overflow: hidden
    background-color: $cFormBackground
    background-image: $formBgImage
    background-size: cover
    background-position: 0 0

    // color: var(--formColor)
    // padding: $mg

.preview-container
    width: 100%
    // max-width: 800px
    height: 100%
    // padding-left: $mg
    // padding-right: $mg

</style>
