<script>
    import Icon from '../components/Icon.vue'
    export default {
        components: { Icon },
        name: "ClosedForm",
        props: {
            form: Object,
        },
    }
</script>


<template>
    <div v-if="form" class="closed-form-comp">
        <div class="message">
            <div v-if="form.style.closed.attachment" class="attachemnt image"><img :src="$bucket + form.style.closed.attachment.path" class="image" alt=""></div>
            <div v-else-if="form.style.logo.path" class="attachemnt logo"><img :src="$bucket + form.style.logo.path" class="image" alt="Logotipo"></div>
            <div v-else class="icon"><Icon name="hand-peace" type="regular"/></div>
            <h1 class="title mt32">{{form.style.closed.title}}</h1>
            <div class="description mt16">{{form.style.closed.description}}</div>
        </div>
        <div class="respondi-link-container">
            <a v-if="!form.style.removeBrand" :href="'https://respondi.app/?utm_source=app&utm_medium=closedform&utm_campaign=Closed%20Form&utm_term=' + form.cus" class="respondi-link">Criado com <strong class="ml4">Respondi.app</strong></a>
        </div>
    </div>
</template>


<style lang="sass" scoped>
.closed-form-comp
    background-color: $cFormBackground
    background-image: $formBgImage
    background-size: cover
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    height: 100%

    .attachemnt
        &.logo .image
            max-width: 200px
            max-height: 200px
            object-fit: contain
            object-position: center
        

    .message
        width: 90%
        max-width: 400px
        text-align: center
        color: rgba($cFormQuestion, 1)
        margin-top: auto

        .title
            font-weight: normal
            font-size: $fzLg
            line-height: 120%

        .description
            font-size: $fzXs
            line-height: 120%

        .icon
            font-size: $fzXxl
            color: rgba($cBrandA, .25)

    .respondi-link-container
        margin-top: auto
        margin-bottom: $mg
    
    
        .respondi-link
            background: $cWhite
            color: $cG5
            padding: $mgXs $mgSm
            font-size: $fzXxs
            border-radius: 20px
            display: inline-flex

            strong
                font-weight: bold
                color: $cPrimary

            &:hover
                box-shadow: 0 0 30px rgba(0,0,0,.15)
            


</style>